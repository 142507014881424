<template>
  <div class="template container-fluid">
    
    
    <h1 class="page-header primary">Page Not Found </h1>
    
       <!-- <Alert message="test" /> -->
      <Alert v-if="alert" v-bind:message="alert"></Alert>

      I'm sorry, but you've arrived at a page that does not exist.
    
  </div>
</template>

<script>
// @ is an alias to /src
import Alert from "@/components/Alert.vue";
import { messageBus } from "@/main";

export default {
  name: "PageNotFound",
  data() {
    return {
      alert: ""
    };
  },
  methods: {
    clearAlert() {
      // Child alert will call this function to clear alert when user closes
      // the alert.  If we don't do this, then sending the same alert after
      // the user closes the alert box, it won't show up.
      this.alert = "";
    },
    eventHandler(msg) {
      console.log("Received event for topic: ${topic} with msg: ", msg);
    }
  },
  created: function() {
    messageBus.$on("topic", this.eventHandler);
  },
  mounted: function() {},
  components: {
    Alert
  }
};
</script>
